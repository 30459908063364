//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchEnoughList, addEnough, delEnough } from "@/api/member";
export default {
  data: function data() {
    return {
      dataList: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      fetchEnoughList().then(function (response) {
        console.log(response);
        _this.dataList = response.data;
      }).catch(function (err) {
        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleAdd: function handleAdd() {
      var obj = {
        gives: "",
        money: "",
        plus: 1
      };
      this.dataList.push(obj);
    },
    handleDelItem: function handleDelItem(row) {
      var _this2 = this;

      console.log(row);
      console.log("删除");
      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664 \u5145".concat(row.money, " \u9001 ").concat(row.gives, "?");
      var title = "删除";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        delEnough(row.id).then(function (res) {
          console.log(res);

          _this2.$message({
            type: "success",
            message: "删除成功"
          });

          var index = _this2.dataList.indexOf(row);

          _this2.dataList.splice(index, 1);
        }).catch(function (err) {
          _this2.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    handleAddItem: function handleAddItem(item) {
      var _this3 = this;

      console.log(item);

      if (item.money == '' || item.gives == '') {
        this.$message({
          type: "warning",
          message: "请填写完整"
        });
        return;
      }

      addEnough(item).then(function (res) {
        console.log(res);

        _this3.$message({
          type: "success",
          message: "添加优惠成功"
        });

        _this3.getTableData();
      }).catch(function (err) {
        _this3.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    }
  }
};