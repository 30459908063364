var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus", size: "mini" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v("优惠项")]
          )
        ],
        1
      ),
      _c(
        "el-row",
        _vm._l(_vm.dataList, function(item) {
          return _c("div", { key: item.id, staticClass: "enough-item" }, [
            _c("span", { staticClass: "text gray" }, [_vm._v("充")]),
            _c(
              "span",
              { staticClass: "number" },
              [
                _c("el-input", {
                  staticClass: "money-text discount-input",
                  attrs: { readonly: item.plus ? false : true },
                  model: {
                    value: item.money,
                    callback: function($$v) {
                      _vm.$set(item, "money", $$v)
                    },
                    expression: "item.money"
                  }
                })
              ],
              1
            ),
            _c("span", { staticClass: "text gray" }, [_vm._v("送")]),
            _c(
              "span",
              { staticClass: "number" },
              [
                _c("el-input", {
                  staticClass: "gives-text discount-input",
                  attrs: { readonly: item.plus ? false : true },
                  model: {
                    value: item.gives,
                    callback: function($$v) {
                      _vm.$set(item, "gives", $$v)
                    },
                    expression: "item.gives"
                  }
                })
              ],
              1
            ),
            _c("span", { staticClass: "text company" }, [_vm._v("元")]),
            !item.plus
              ? _c(
                  "span",
                  {
                    staticClass: "del",
                    on: {
                      click: function($event) {
                        return _vm.handleDelItem(item)
                      }
                    }
                  },
                  [_c("span", [_vm._v("X")])]
                )
              : _vm._e(),
            item.plus
              ? _c(
                  "span",
                  {
                    staticClass: "add",
                    on: {
                      click: function($event) {
                        return _vm.handleAddItem(item)
                      }
                    }
                  },
                  [_c("span", [_vm._v("+")])]
                )
              : _vm._e()
          ])
        }),
        0
      ),
      _c("el-row")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }